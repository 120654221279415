import React from 'react';
import { BaseLayout } from '../../components/layout/base/base-layout';
import { CollectionTemplate } from './collection-template';
import { SEO } from '../../components/layout/seo';

// eslint-disable-next-line react/prop-types
const CollectionPageTemplate = ({ pageContext }) => (
  <BaseLayout>
    <SEO
      title={pageContext.seoTitle || pageContext.name}
      description={pageContext.seoDescription || pageContext.description}
    />
    <CollectionTemplate pageContext={pageContext} />
  </BaseLayout>
);

export default CollectionPageTemplate;
