import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Typography } from 'antd';
import { Link } from 'gatsby';
import css from './link-card.module.less';

const { Title } = Typography;

export const LinkCard = ({
  title,
  image,
  overlay,
  link,
  tags,
}) => {
  let colorClass;

  switch (overlay) {
    default:
      if (overlay < 10) {
        break;
      }
      if (overlay < 25) {
        colorClass = css.green;
      }
      if (overlay < 50) {
        colorClass = css.yellow;
      }
      if (overlay < 100) {
        colorClass = css.orange;
      }
      if (overlay < 150) {
        colorClass = css.blue;
      }
      if (overlay < 300) {
        colorClass = css.red;
      }
      colorClass = css.violet;
      break;
    case 'red':
      colorClass = css.red;
      break;
    case 'blue':
      colorClass = css.blue;
      break;
    case 'green':
      colorClass = css.green;
      break;
    case 'yellow':
      colorClass = css.yellow;
      break;
    case 'orange':
      colorClass = css.orange;
      break;
    case 'violet':
      colorClass = css.violet;
      break;
  }

  return (
    <Link to={link} className={`${colorClass} ${css.card}`} style={{ backgroundImage: `url(${image})` }}>
      <Title level={2} className={css.title}>{title}</Title>
      {tags.map((tag) => <Tag key={tag}>{tag}</Tag>)}
    </Link>
  );
};

LinkCard.defaultProps = {
  image: '',
  overlay: null,
  link: '',
  tags: [],
};

LinkCard.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  image: PropTypes.string,
  overlay: PropTypes.oneOf([PropTypes.number, 'red', 'blue', 'green', 'yellow', 'orange', 'violet']),
  link: PropTypes.string,
};
