import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Card, Col, Divider, Image, Row, Space, Statistic, Typography,
} from 'antd';
import { FiEdit } from 'react-icons/fi';
import { CarouselCard } from '../../components/cards/carousel-card/carousel-card';
import { PrivateComponent } from '../../components/auth/authorization/private-component';
import { Description } from '../../components/common/description';
import { LinkCard } from '../../components/cards/link-card/link-card';

const { Title } = Typography;

export const CollectionTemplate = ({ pageContext: collection }) => (
  <>
    <Row gutter={[32, 32]} justify="center">
      {collection.backgroundImage?.url
        ? (
          <Col flex="0 1 550px">
            <Image
              src={collection.backgroundImage.url}
              width={550}
              height={400}
              alt={collection.backgroundImage.alt || 'Event photo'}
            />
          </Col>
        )
        : null}

      <Col flex="0 1 550px">
        <Title level={1}>
          {collection.name}
        </Title>
        <PrivateComponent isStaff permissions={['MANAGE_PRODUCTS']}>
          <a href={`${process.env.GATSBY_SALEOR_DASHBOARD_URL}collections/${collection.id}`}>
            <Button
              icon={<FiEdit />}
              size="large"
            >
              Edit in dashboard
            </Button>
          </a>
        </PrivateComponent>
        <Description
          descriptionJson={collection.descriptionJson}
          description={collection.description}
        />
        <Space>
          {collection.metadata?.map((item) => (
            <Card bordered={false} key={item.key}>
              <Statistic
                title={item.key}
                value={item.value}
              />
            </Card>
          ))}
        </Space>
      </Col>

    </Row>
    <Row gutter={[32, 32]} justify="center">
      <Col flex="1 1 100%">
        <Title level={2}>Start Planning</Title>
        <Divider />
      </Col>
      <Col xs={24} md={12} xl={8} xxl={6}>
        <LinkCard
          title={`Find suppliers for ${collection.name}`}
          overlay="red"
          link={`/search/?collections=${collection.id}`}
        />
      </Col>
      {collection.products.edges.map(
        ({ node: product }) => (
          <Col xs={24} md={12} xl={8} xxl={6} key={product.slug}>
            <CarouselCard
              variantCount={product.variants?.length}
              productName={product.name}
              productSlug={product.slug}
              variantId={product.defaultVariant?.id}
              attributes={product.attributes}
              images={product.images}
              productType={product.productType}
              productLocation={product.location}
            />
          </Col>
        ),
      )}
      <Col xs={24} md={12} xl={8} xxl={6}>
        <LinkCard title="Explore Other Events" overlay="blue" link="/collections" />
      </Col>
    </Row>
  </>
);

CollectionTemplate.propTypes = {
  pageContext: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    seoDescription: PropTypes.string,
    seoTitle: PropTypes.string,
    description: PropTypes.string,
    descriptionJson: PropTypes.string,
    backgroundImage: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    metadata: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    })),
    products: PropTypes.shape({
      edges: PropTypes.arrayOf(PropTypes.shape({
        node: PropTypes.shape({
          name: PropTypes.string,
          attributes: PropTypes.arrayOf(PropTypes.shape({
            attribute: PropTypes.shape({
              name: PropTypes.string,
              inputType: PropTypes.string,
            }),
            values: PropTypes.arrayOf(PropTypes.shape({
              name: PropTypes.string,
            })),
          })),
          defaultVariant: PropTypes.shape({
            id: PropTypes.string,
          }),
          productType: PropTypes.shape({
            name: PropTypes.string,
            slug: PropTypes.string,
          }),
          images: PropTypes.arrayOf(PropTypes.shape({
            url: PropTypes.string,
          })),
          location: PropTypes.shape({
            geography: PropTypes.shape({
              type: PropTypes.string,
              coordinates: PropTypes.arrayOf(PropTypes.number),
            }),
            address: PropTypes.shape({
              city: PropTypes.string,
              postalCode: PropTypes.string,
            }),
          }),
          variants: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
            sku: PropTypes.string,
          })),
        }),
      })),
    }),
  }).isRequired,
};
